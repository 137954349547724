import { makeAutoObservable } from "mobx";

class LoadingStore {
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}

export default new LoadingStore();