import { makeAutoObservable } from "mobx";
import update from 'immutability-helper';

class ModalStore {
  modals: {Component: any, props: any, isOpen: boolean}[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  
  open = (Component: any, props: any) => {
    this.modals = [...this.modals, {Component, props, isOpen: true}];
  };

  close = (Component: any) => {
    const index = this.modals.findIndex(modal => modal.Component === Component);
    
    this.modals = update(this.modals, {
      [index]: {
        isOpen: { 
          $set: false,
        },
      },
    });
    
    setTimeout(() => this._close(index), 200);
  };

  _close = (index: number) => {
    this.modals = update(this.modals, {
      $splice: [[index, 1]],
    });
  }
}

export default new ModalStore();