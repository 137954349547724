import React from 'react';
import dynamic from 'next/dynamic';
import ModalStore from '../../stores/ModalStore';
import { observer } from 'mobx-react-lite';

export const modals = {
  shareModal: dynamic(() => import('./ShareModal')),
  디탄발Modal: dynamic(() => import('./디탄발Modal')),
  MessageModal: dynamic(() => import('./MessageModal')),
  텍스트Modal: dynamic(() => import('./텍스트Modal')),
  QRModal: dynamic(() => import('./QRModal')),
  이미지Modal: dynamic(() => import('./이미지Modal')),
  IOS웹앱설치안내Modal: dynamic(() => import('./IOS웹앱설치안내Modal')),
};

const Modals = () => {
  return (
    <div className="modals">
      {ModalStore.modals.map((modal, index) => {
        const { Component, isOpen, props } = modal;
        const { onSubmit, ...restProps } = props;

        const onClose = () => {
          ModalStore.close(Component)
        };

        const handleSubmit = () => {
          if (typeof onSubmit === 'function') {
            onSubmit();
          }
          onClose();
        };

        return (
          <Component
            {...restProps}
            key={index}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
          />
        );
      })}
    </div>
  );
};

export default observer(Modals);
