import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { enableStaticRendering, observer } from 'mobx-react-lite';
import LoadingStore from '../stores/LoadingStore';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Head from 'next/head';
import ReactModal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import Modals from '../components/modal/Modals';
import 'react-toastify/dist/ReactToastify.css';
import Script from 'next/script';

import PrimeReact from 'primereact/api';
import { Router } from 'next/router';

PrimeReact.zIndex = {
  modal: 3659,
  overlay: 3659,
  menu: 3659,
  tooltip: 3659,
  toast: 3659
}
enableStaticRendering(typeof window === 'undefined');


ReactModal.setAppElement('#__next');
ReactModal.defaultStyles = {
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    zIndex: 3649,
    backgroundColor: '#000000c8',
  },
  content: {
    ...ReactModal.defaultStyles.content,
    zIndex: 3650,
    maxHeight: 200,
    top: '50%',
    bottom: 0,
    transform: 'translateY(-50%)',
  }
};

const LoadingSpinner = styled.div({
  display: 'flex',
  position: 'fixed',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(2px)',
  zIndex: 9999999,
  top: 0,
  left: 0,
});

export const MyApp = (props: any) => {
  const {Component, pageProps}: AppProps = props;
  
  useEffect(() => {
    const onRouteChangeStart = () => {
      LoadingStore.setIsLoading(true);
    }

    const onRouteChangeEnd = () => {
      LoadingStore.setIsLoading(false);      
    }
    
    if (typeof window !== 'undefined') {

      const setVh = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

      setVh();

      window.addEventListener('resize', setVh);

      document.body.addEventListener('touchmove', e => e.preventDefault());

      Router.events.on('routeChangeStart', onRouteChangeStart);
      Router.events.on('routeChangeComplete', onRouteChangeEnd);
      Router.events.on('routeChangeError', onRouteChangeEnd);
    }

    return () => {
      if (typeof window !== 'undefined') {
        Router.events.off('routeChangeStart', onRouteChangeStart);
        Router.events.off('routeChangeComplete', onRouteChangeEnd);
        Router.events.off('routeChangeError', onRouteChangeEnd);
      }
    };
  }, []);

  const theme = useMemo(() => createTheme({
    palette: {
      primary: {
        main: '#000'
      },
      secondary: {
        main: '#2ac2bc',
      },
      
    },
    typography: {
      allVariants: {
        color: '#262626',
      },
      fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderColor: '#dbdbdb',
          },
        },
      },
    },
  }), []);

  return (
    <>
    <Head>
      <title>Magic Link</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      <meta name="theme-color" content="white" />
      <meta name="google" content="notranslate" />
    </Head>
    
    <CssBaseline />

    <ThemeProvider theme={theme}>
      <Container id="container" maxWidth="xs" disableGutters sx={{height: 1}}>
        <Component {...pageProps} />
      </Container>

      <Modals />
      {LoadingStore.isLoading && (
        <LoadingSpinner>
          <ScaleLoader width="11px" height="35px" color="#245454" radius="3px" />
        </LoadingSpinner>
      )}
    </ThemeProvider>

    <ToastContainer 
      position="top-center"
      autoClose={2400}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme="colored" />
      
    <Script src="https://developers.kakao.com/sdk/js/kakao.min.js" />
    </>
  );
}
export default observer(MyApp);