// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// Sentry.init({
//   dsn: SENTRY_DSN || 'https://dac7cb0c5a304f11b74b5026546d66b8@o1223856.ingest.sentry.io/6368356',
//   tracesSampleRate: 0.5,
//   environment: "production",
//   tracesSampler: (samplingContext) => {
//     if (samplingContext.transactionContext.name === '/health_check') {
//       return 0;
//     }

//     return 0.5;
//   },
// });
